import { render, staticRenderFns } from "./PortfolioView.vue?vue&type=template&id=1020b578&scoped=true&"
import script from "./PortfolioView.vue?vue&type=script&lang=ts&"
export * from "./PortfolioView.vue?vue&type=script&lang=ts&"
import style0 from "./PortfolioView.vue?vue&type=style&index=0&id=1020b578&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1020b578",
  null
  
)

export default component.exports