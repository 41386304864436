







export default {
  mounted(){
    document.title = 'Crypto Coin App';
  }
}
