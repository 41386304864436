











import { Component, Vue } from "vue-property-decorator";
import CoinEventLogTableComponent from "../components/CoinEventLogTableComponent.vue";

@Component({
  components: {
    CoinEventLogTableComponent,
  },
})
export default class CoinEventLogView extends Vue {
  coinNameRetrieved: string = this.$route.params.coinName;
}

