




































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({})
export default class DeleteCoinModal extends Vue {

  @Prop({ default: false })
  isActive!: boolean;

  @Prop({default:false})
  loadingStatus!:boolean;

  @Prop()
  coinName!:string;

  deleteCoinMethod(){
    this.$emit(`triggerDeleteCoinEvent`)
  }

  closeModalFunction(){
    this.$emit(`closeModal`)
  }
}
