






















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { CoinModalFieldData } from '../../../models/form-data.model'
import { 
  validateInputNumberPrecisionTwelveScaleSix, 
  validateInputNumberPrecisionNineScaleTwo 
  } from '../../../utils/validation'
import { 
  precisionTwelveScaleSixFieldsArray, 
  precisionNineScaleTwoFieldsArray 
  } from '../../../utils/array-fields-validation'

@Component({
  components:{}
})

export default class CoinDetailsModalComponent extends Vue{

  @Prop()
  coinEventDetails!: CoinModalFieldData

  @Prop({default: null})
  eventDateFromSelectedEvent!: Date | null

  @Prop({ default: false })
  updateEventModalType!: boolean

  partialCoinModalDetails: CoinModalFieldData = {
    quantity: null,
    marketPrice: null,
    exchangePremium: null,
    networkFee: null,
    dateTime: null
  }

  isAnUpdateEventModalType = false;

  initialDateOfEvent: Date | null = null;

  isDisabled = true;

  mounted(){
    // FOLLOWING MEANT FOR UPDATE EVENTS MODAL ONLY
    // Set the partialCoinModalDetails to have the data from the parent component (coinEventDetails)
    // when mounted
    // If the modal using this component is the event update modal, set isAnUpdateEventModalType to the prop (which should be true)
    // (So far it is used to set the revert button on or off)
    if(this.updateEventModalType){
      this.partialCoinModalDetails = {
        ...this.coinEventDetails, 
        dateTime: new Date(this.coinEventDetails.dateTime as Date)
      }
      this.isAnUpdateEventModalType = this.updateEventModalType
    }

    // Set the initialDateOfEvent to the selected event row's event date
    // This is also to ensure if any re-mounts (rediting of updates in modal by user)
    // was done, the initialDateOfEvent will always refer to the selected event's event date only
    if(this.eventDateFromSelectedEvent){
      this.initialDateOfEvent = this.eventDateFromSelectedEvent as Date;
    }
  }

// Function that emits the changes of any of the fields of the modal 
// back to the parent component through emission (this.$emit)
// Arguments include the new value and the partialCoinModalDetails object's key name
  detectValueFromField(){
    // Check if the numeric field values are number type & conform to their respective precision & scale (conform to the regex sets in validation.ts)
    // No zero values also (edge case not put into consideration where network fee or exchange premium could be 0 (super remote possibility)
    let hasError = false;
    for(let key in this.partialCoinModalDetails){
      const newKey = key as keyof CoinModalFieldData;
      if(
        newKey !== 'dateTime' &&
        this.partialCoinModalDetails[newKey]
        ){
          if(
            precisionTwelveScaleSixFieldsArray.includes(newKey.toString()) &&
            (
              Number(this.partialCoinModalDetails[newKey]) === 0 ||
              !validateInputNumberPrecisionTwelveScaleSix(this.partialCoinModalDetails[newKey] as string)
            )
            ){
              hasError = true;
          }else if(
            precisionNineScaleTwoFieldsArray.includes(newKey.toString()) &&
            (
              Number(this.partialCoinModalDetails[newKey]) === 0 ||
              !validateInputNumberPrecisionNineScaleTwo(this.partialCoinModalDetails[newKey] as string)
            )
            ){
              hasError = true;
          }
        }else if(
          newKey !== 'dateTime' &&
          (
            !this.partialCoinModalDetails[newKey] ||
            Number(this.partialCoinModalDetails[newKey]) === 0
          )
        ){
          hasError = true;
        }else if(
          newKey === 'dateTime' &&
          !this.partialCoinModalDetails[newKey]
        ){
          hasError = true;
        }
    }
    this.isDisabled = hasError;
    this.$emit('retrievePartialCoinModalDetails', this.partialCoinModalDetails, this.isDisabled)
  }

// Function for the date if click on Revert, the date reverts back to previous state
// If click on Now, set the date to current date
  dateButtonFunctionality(buttonMode:string){
    if( buttonMode === 'revert' ){
      this.partialCoinModalDetails.dateTime = this.initialDateOfEvent;
    }else if( buttonMode === 'now' ){
      this.partialCoinModalDetails.dateTime = new Date();
    }
    this.detectValueFromField()
  }

}

