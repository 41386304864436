




































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CoinEventLog } from '../../models/table.model';
@Component({})

export default class DeleteCoinEventModal extends Vue {

  @Prop()
  isActive!: boolean;

  @Prop({default:false})
  loadingStatus!:boolean;

  @Prop()
  coinName!:string;

  @Prop()
  coinEventDetails!: CoinEventLog;

  eventId: number | null = null;
  eventType = ''
  eventDateFromSelectedEvent: string | null = null;

  @Watch('coinEventDetails')
  retrieveSelectedCoinEvent(){
    if(this.coinEventDetails){
      const { 
        id,
        eventType, 
        eventDate
      } = this.coinEventDetails
      this.eventId = id;
      this.eventType = eventType === 2 ? 'BUY' : 'SELL'
      this.eventDateFromSelectedEvent = new Date(eventDate).toLocaleString();
    }
  }

  deleteCoinMethod(){
    this.$emit(`triggerDelete`)
  }

  closeModalFunction(){
    this.$emit(`closeModal`)
  }

}
