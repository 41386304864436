



































import { Emit, Prop, Vue, Component } from 'vue-property-decorator'

@Component({

})

export default class UpdateCoinEventConfirmationModal extends Vue {
  
  @Prop({ default:false })
  isActive!: boolean;

  @Prop()
  updateMessage!: string;

  @Prop()
  eventId!: number;

  @Prop({default:false})
  loadingStatus!:boolean;

  triggerUpdateConfirmation(){
    this.$emit('triggerUpdateConfirmation');
  }

  @Emit('closeConfirmationModal')
    closeConfirmationModal(){
      return;
  }

}
