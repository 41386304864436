import { render, staticRenderFns } from "./CoinEventLogView.vue?vue&type=template&id=e43e2f50&scoped=true&"
import script from "./CoinEventLogView.vue?vue&type=script&lang=ts&"
export * from "./CoinEventLogView.vue?vue&type=script&lang=ts&"
import style0 from "./CoinEventLogView.vue?vue&type=style&index=0&id=e43e2f50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e43e2f50",
  null
  
)

export default component.exports