









import { Component, Vue } from "vue-property-decorator";
import CoinTableComponent from "../components/CoinTableComponent.vue";

@Component({
  components: {
    CoinTableComponent,
  },
})
export default class PortfolioView extends Vue {}
